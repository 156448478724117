import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./App.css";
import Home from "./Screens/Home";
import { Route, Routes } from "react-router-dom";
import ServicesWrapper from "./Screens/ServicesWrapper";
import ContactScreen from "./Screens/ContactScreen";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import AppointmentScreen from "./Screens/AppointmentScreen";
import AdminScreen from "./Screens/AdminScreen";
import { client } from "./Apollo/client";
import { ApolloProvider } from "@apollo/client";
import HistoireScreen from "./Screens/HistoireScreen";
import { setLangAsync } from "./services/I18nSlice";
import PaymentScreen from "./Screens/PaymentScreen";
import FailedScreen from "./Screens/FailedScreen";
import SuccessScreen from "./Screens/SuccessScreen";
import { PersistGate } from "redux-persist/integration/react";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<h1>Loading...</h1>}  persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => dispatch(setLangAsync()), []);

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/services" element={<ServicesWrapper />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/appointment" element={<AppointmentScreen />} />
          <Route path="/admin" element={<AdminScreen />} />
          <Route path="/histoire" element={<HistoireScreen />} />
          <Route path="/failed" element={<FailedScreen />} />
          <Route path="/success" element={<SuccessScreen />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default AppWrapper;
