import axios from "axios";

// type is either "payment" or "session"
// lang is either "FR" or "EN"
// name is the customer name
// email is the customer email
// title is the session name
// amount is a number representing the price of the session
// startDate is the session start date
export const sendEmailApi = async ({
  type,
  lang,
  name,
  email,
  title,
  amount,
  startDate,
}) => {
  try {
    const res = await axios.post(
      "https://px5rhay4qp55nh5jj2dfre2c540nxvfy.lambda-url.us-east-2.on.aws/",
      {
        customer: {
          email,
          name,
        },
        session: {
          name: title,
          date: startDate,
          price: amount,
        },
        lang,
        type,
      },
      {
        headers: {
          auth: "be712802-a2c7-4da1-83d4-771f0c753ccf",
        },
        mode: "no-cors",
      }
    );
    return res?.data;
  } catch (e) {
    return null;
  }
};
