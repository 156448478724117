import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  defaultLang,
  supportedLangs,
} from "../config/i18nConfig";

import { fetchTranslations } from "../languageAPI/i18nAPI";

const initialState = {
  status: "loading",
  lang: defaultLang, 
  supportedLangs: { ...supportedLangs },

  translations: {},
};

export const setLangAsync = createAsyncThunk(
   "i18n/setLangAsync",
    (lang) => {
      return fetchTranslations(
         lang || 'en',
      );
   },
);

export const i18nSlice = createSlice({
   name: "i18n",
   initialState,
   reducers: {
      setLang: (state, action) => {
      state.lang = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(setLangAsync.pending, (state) => {
        state.status = "loading";
      });
      builder.addCase(
        setLangAsync.fulfilled,
        (state, action) => {
          state.translations = action.payload;
          state.status = "idle";
        },
      );
    },
});



export const selectTranslations = (state) =>
   state.i18n.translations;
export default i18nSlice.reducer;
export const { setLang } = i18nSlice.actions;

