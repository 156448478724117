import React from "react";
import Nav from "../Components/Nav";
import "../styles/FailedScreen.css";
import { blowfishDecrypt } from "../Helpers/EncryptionFunctions";
import FooterSpecial from "../Components/FooterSpecial";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

function FailedScreen() {
  const queryString = window.location.search;
  console.log(queryString);
  const paramString = queryString.split("?")[1];
  console.log(paramString);
  const urlParams = new URLSearchParams(paramString);
  const Data = urlParams.get("Description");
  //const res = blowfishDecrypt(Data);
  //  console.log('arr', arrOfParameters);
  //const description = arrOfParameters[5].substring(12);
  //console.log('description', description);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch({ type: "payout/add", payload: {} });
    navigate("/services");
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="Failed-Screen-Wrapper">
        <Nav />
        <div className="info-wrapper">
          <h1 className="error-description">Error: {Data}</h1>
          <h2 className="return-button" onClick={handleClick}>
            Return
          </h2>
        </div>
      </div>
      <FooterSpecial />
    </div>
  );
}

export default FailedScreen;
