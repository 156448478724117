const initialState = "en";

export default function languageReducer(state = initialState, action) {
    switch (action.type) {
    case "language/en":
        return "en";
    case "language/fr":
        return "fr";
    default:
        return state;
    }
}
