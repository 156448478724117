import React from 'react'
import '../../styles/HomeDescription.css'
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";

function HomeDescription() {

   const t = useSelector(selectTranslations);

   return (
      <div className='description-wrapper'>
         <h1>{t.home_description_title}</h1>
         <h4>{t.home_description_1}</h4>
         <p>{t.home_description_2}</p>
         <p>{t.home_description_3}</p>
         <div className="contact-button">
            <a href="/contact">{t.contact_btn}</a>
         </div>
      </div>
   )
}

export default HomeDescription
