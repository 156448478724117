import React from "react";
import "../../styles/Thumbnail.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";
import { BsTelephone } from "react-icons/bs";

function Thumbnail() {
  const t = useSelector(selectTranslations);

  return (
    <div className="thumbnail-wrapper">
      <div className="thumbnail-info">
        <div className="thumbnail-header">
          <h1>{t.Title1}</h1>
          <h1>{t.Title2}</h1>
        </div>
        <p>{t.Thumbnail_description} </p>
        <div className="services-btn">
          <a href="/services">{t.service_btn}</a>
        </div>
        <hr className="thumbnail-break" />
        <div className="freecall-wrapper">
          <BsTelephone size={28} color="white" />
          <div className="freecall-text">
            <p>
              <b>{t.free_call}</b> {t.free_call_sub}
            </p>
            <p>
              <a href="/contact" style={{ color: "#F4B208" }}>
                {t.free_call_CTA}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="thumbnail-picture" />
    </div>
  );
}

export default Thumbnail;
