import React, { useState } from "react";
import Nav from "../Components/Nav.js";
import { useLocation } from "react-router-dom";
import keys from "../constants.js";
import moment from "moment";
import "../styles/PaymentScreen.css";
import Footer from "../Components/Footer.js";
import { useSelector } from "react-redux";

function PaymentScreen() {
  const location = useLocation();
  const len = location?.state?.DATA?.length || 0;
  const data = location?.state?.DATA || "";
  const language = useSelector((state) => state.language);

  return (
    <div>
      <Nav />
      <div className="cart-info">
        <h1>Cart</h1>
        <ul>
          <li>Name: {location?.state?.appointmentInfo?.name}</li>
          <li>Email: {location?.state?.appointmentInfo?.email}</li>
          <li>Phone Number: {location?.state?.appointmentInfo?.phone}</li>
          <li>Service: {location?.state?.appointmentInfo?.title}</li>
          <li>
            Time:{" "}
            {moment(location?.state?.appointmentInfo?.startDate).format(
              "dddd DD HH:mm"
            )}{" "}
            to{" "}
            {moment(location?.state?.appointmentInfo?.endDate).format("HH:mm")}
          </li>
          <li>Amount to Pay: {location?.state?.appointmentInfo?.amount}€</li>
        </ul>

        <div style={{ fontSize: 14, marginTop: "40px" }}>
          {language === "en" ? (
            <div>
              <b>Refund policy:</b> <br />
              - Cancellation 48h before = No refund <br />
              - Cancellation 3 days before = 50% refund <br />
              - Cancellation 5 days before = 100% refund <br />
              If you want to change the day of your appointment, please contact
              Aya via the number +33 7 66 85 79 97
            </div>
          ) : (
            <div>
              <b>Conditions de remboursement: </b>
              <br />
              - Annulation 48h avant = Pas de remboursement <br />
              - Annulation 3 jours avant = 50% remboursé <br />
              - Annulation 5 jours avant = 100% remboursé <br />
              Si vous désirez modifier le jour de votre rendez-vous, veuillez
              contacter directement Aya au +33 7 66 85 79 97
            </div>
          )}
        </div>

        <h1 className="proceed-button">
          <a
            href={`https://paymentpage.axepta.bnpparibas/payssl.aspx?MerchantID=${keys?.MID}&Len=${len}&DATA=${data}`}
          >
            Continue to secure payment
          </a>
        </h1>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentScreen;
