import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import histoire3 from '../images/Histoire/histoire3.jpeg'
import histoire2 from '../images/Histoire/histoire2.jpg'

import '../styles/HistoireScreen.css'
import { useSelector } from 'react-redux'
import { selectTranslations } from '../services/I18nSlice'

function HistoireScreen() {

   const t = useSelector(selectTranslations);

  return (
    <div>
      <Nav /> 
      <div className="histoire-wrapper">
         <div className="first-section">
            <div className='histoire-div'>
               <h1>I'm Aya</h1>
               <p className='hitoire-div-text'>{t.histoire_text_1}</p>
            </div>
            <img className='histoire-img first-image' src={histoire3} alt="" />
         </div>
         <div className="break"></div>
         <div className="second-section">
            <img className='histoire-img' src={histoire2} alt="" />
            <div className='histoire-div second'>
               <p>{t.histoire_text_2}</p>
            </div>
         </div>
      </div>

      <Footer />
    </div>
  )
}

export default HistoireScreen
