import { useDispatch, useSelector } from "react-redux";
import { setLangAsync } from "./I18nSlice";
import ReactCountryFlag from "react-country-flag";

export function LangSwitcher() {
  const dispatch = useDispatch();

  const handleClick = async (lang) => {
    const translations = await setLangAsync(lang);
    await dispatch(translations);
    dispatch({ type: `language/${lang}` });
  };

  return (
    <div style={{ display: "flex" }}>
      <ReactCountryFlag
        countryCode="GB"
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
        cdnSuffix="svg"
        title="GB"
        onClick={() => handleClick("en")}
        style={{ marginRight: 10, cursor: "pointer", fontSize: "1.2em" }}
      />
      <ReactCountryFlag
        countryCode="FR"
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
        cdnSuffix="svg"
        title="FR"
        onClick={() => handleClick("fr")}
        style={{ cursor: "pointer", fontSize: "1.2em" }}
      />
    </div>
  );
}
