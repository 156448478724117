import React from "react";
import "../../styles/ContactHeader.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";

function ContactHeader() {
  const t = useSelector(selectTranslations);

  return (
    <div className="contact-header-wrapper">
      <div className="contact-header-info">
        <h1>{t.contact_title}</h1>
        <p>59 Rue de Courcelles</p>
        <p>75008 Paris, France</p>
        <br />
        <p>aya@pacemfamily.com</p>
        <p>+33 7 66 85 79 97</p>
        <form>
          <input type="text" placeholder={t.name_placeholder} />
          <input type="text" placeholder={t.email_placeholder} />
          <input type="text" placeholder={t.phone_placeholder} />
          <textarea
            cols="30"
            rows="10"
            placeholder={t.message_placeholder}
          ></textarea>
          <div>
            <a href="">{t.send_btn}</a>
          </div>
        </form>
      </div>
      <div className="contact-header-picture"></div>
    </div>
  );
}

export default ContactHeader;
