import React, { useState } from 'react'
import '../styles/Nav.css'
import logo from '../images/logo.png'
import { BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"
import { LangSwitcher } from '../services/LangSwitcher'
import { useSelector } from "react-redux";
import { selectTranslations } from "../services/I18nSlice";

export default function Nav() {
   const [mobile, setMobile] = useState(false);
   const t = useSelector(selectTranslations);
   function handleClick() {
      setMobile(prev => !prev);
   }

   return (
      <div className='nav-wrapper'>
         <div className="logo-div">
            <a href='/'><img src={logo} alt="logo" className='nav-logo'/></a>
            <ul className="nav-list-links-mobile">
              <li className="nav-list-link-mobile">
                  <a href='https://www.linkedin.com/company/leap-to-success-coaching'
                  target="_blank" rel='noreferrer'>
                     <BsLinkedin />
                  </a>
               </li>
               <li className="nav-list-link-mobile">
                  <a href='https://www.instagram.com/leaptosuccesscoaching/'
                  target="_blank" rel='noreferrer'>
                     <BsInstagram />
                  </a>
               </li>
               <li className="nav-list-link-mobile">
                  <a href='https://www.facebook.com/leaptosuccesscoaching'
                  target="_blank" rel='noreferrer'>
                     <BsFacebook />
                  </a>
               </li>
            </ul>
         </div>
         <ul className='nav-list-links'>
            <li className='nav-list-link'><a href='/'>{t.home_nav}</a></li>
            <li className='nav-list-link'><a href='/services'>{t.services_nav}</a></li>
            <li className='nav-list-link'><a href='/histoire'>{t.story_nav}</a></li>
            <li className='nav-list-link'><a href='/contact'>{t.contact_nav}</a></li>
            <li className='nav-list-link'>
               <a href='https://www.linkedin.com/company/leap-to-success-coaching'
               target="_blank" rel='noreferrer'>
                  <BsLinkedin />
               </a></li>
            <li className='nav-list-link'>
               <a href='https://www.instagram.com/leaptosuccesscoaching/'
               target="_blank" rel='noreferrer'>
                  <BsInstagram />
               </a>
            </li>
            <li className='nav-list-link'>
               <a href='https://www.facebook.com/leaptosuccesscoaching' target="_blank" rel='noreferrer'>
                  <BsFacebook />
               </a>
            </li>
            <li><LangSwitcher /></li>

         </ul>
         <div className="mobile-navigation">
            <div className="hamburger-icon" onClick={handleClick}>
               <div className="hamburger line1"></div>
               <div className="hamburger line2"></div>
               <div className="hamburger line3"></div>
            </div>
            <div className={mobile ? "drawer drawer-active" : "drawer"}>
               <div className="drawer-header">
                  <div className="close" onClick={handleClick}>
                     <AiOutlineClose color='white' />
                  </div>
               </div>

               <ul className="drawer-links">
                  <li className='drawer-link'><a href='/'>{t.home_nav}</a></li>
                  <li className='drawer-link'><a href='/services'>{t.services_nav}</a></li>
                  <li className='drawer-link'><a href='/histoire'>{t.story_nav}</a></li>
                  <li className='drawer-link'><a href='/contact'>{t.contact_nav}</a></li>
                  <li><LangSwitcher /></li>
                  <ul className="nav-list-links-mobile">
                     <li className="nav-list-link-mobile">
                        <a href='https://www.linkedin.com/company/leap-to-success-coaching'target="_blank" rel='noreferrer'>
                           <BsLinkedin fill='white' size={20}/>
                        </a>
                     </li>
                     <li className="nav-list-link-mobile">
                        <a href='https://www.instagram.com/leaptosuccesscoaching/'target="_blank" rel='noreferrer'>
                           <BsInstagram fill='white' size={20}/>
                        </a>
                     </li>
                     <li className="nav-list-link-mobile">
                        <a href='https://www.facebook.com/leaptosuccesscoaching'
                        target="_blank" rel='noreferrer'>
                           <BsFacebook fill='white' size={20}/>
                     </a>
                  </li>
               </ul>
               </ul>
            </div>
         </div>
      </div>
   )
}
