import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";
import "../../styles/ServicesList.css";
import Modal from "../Modal";
import SingleService from "./SingleService";

function ServicesList() {
  const t = useSelector(selectTranslations);
  const [showModal, setshowModal] = useState(false);
  const [description, setDescription] = useState("");

  const infoClicked = (text) => {
    let tmp = createMarkup(text);
    setDescription(tmp);
    setshowModal(true);
  };

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <div className="services-list">
      <SingleService
        title={t.service_SII}
        infoClicked={() => infoClicked(t.tooltip_1)}
        price={`350€/$`}
        service="threeHour"
        priceText={350}
      />
      <SingleService
        title={t.service_MBTI}
        infoClicked={() => infoClicked(t.tooltip_2)}
        price={`350€/$`}
        service="threeHour"
        priceText={350}
      />
      <SingleService
        title={t.service_coaching}
        price={`250€/$/h`}
        priceText={100}
        service="oneHour"
      />
      <SingleService
        title={t.service_resume}
        price={t.on_demand}
        priceText={"onDemand"}
        contact={true}
      />
      <SingleService
        title={t.service_ncda}
        price={`${t.on_demand} 1200€/$`}
        priceText={100}
        contact={true}
      />
      {showModal && (
        <Modal show={showModal}>
          <div
            className="service-modal"
            dangerouslySetInnerHTML={description}
          />
          <div className="modal-button" onClick={() => setshowModal(false)}>
            <p style={{ color: "white" }}>{t.close}</p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ServicesList;
