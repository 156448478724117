import React from 'react'
import '../../styles/Clients.css';
import Ressource1 from '../../images/Ressources/Ressource1.jpg'
import Ressource2 from "../../images/Ressources/Ressource2.jpg"
import Ressource3 from "../../images/Ressources/Ressource3.jpg"
import Ressource4 from "../../images/Ressources/Ressource4.jpg"
import Ressource5 from "../../images/Ressources/Ressource5.jpg"
import Ressource6 from "../../images/Ressources/Ressource6.jpg"
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";


function Clients() {

  const t = useSelector(selectTranslations);

  return (
    <div className='clients-wrapper'>
       <h1>{t.Resources}</h1>
       <div className="clients-pictures">
          <img src={Ressource1} alt="" className='ressource-img' />
          <img src={Ressource2} alt="" className='ressource-img' />
          <img src={Ressource3} alt="" className='ressource-img' />
          <img src={Ressource4} alt="" className='ressource-img' />
          <img src={Ressource5} alt="" className='ressource-img' />
          <img src={Ressource6} alt="" className='ressource-img' />

       </div>
    </div>
  )
}

export default Clients
