import React from "react";
import "../../styles/ServicesHeader.css";
import { selectTranslations } from "../../services/I18nSlice";
import ReactTooltip from "react-tooltip";

import { useSelector } from "react-redux";
function ServicesHeader() {
  const t = useSelector(selectTranslations);
  return (
    <div className="services-header-wrapper">
      <h1>{t.service_btn}</h1>
    </div>
  );
}

export default ServicesHeader;
