import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
   apiKey: "AIzaSyDWcvBZ72fT6fyU8SCcg0io5DT5qYJWw0I",
   authDomain: "leap-to-success-9b20a.firebaseapp.com",
   projectId: "leap-to-success-9b20a",
   storageBucket: "leap-to-success-9b20a.appspot.com",
   messagingSenderId: "907565568278",
   appId: "1:907565568278:web:847db91763249ba8850037",
   measurementId: "G-7XHQGPZKN4"
 };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app)