import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useDispatch } from "react-redux"
import { auth } from "./init";

import "../styles/AuthenticationScreen.css";


export const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    if(auth && email && password){
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        if (res && res?.user) {
          const { accessToken } = res?.user;
          if (accessToken) {
            dispatch({ type: "user/login", payload: { accessToken } });
            window.sessionStorage.setItem("authed", true);
            return { success: true };
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

  };

  return (
    <div className='admin-auth-wrapper'>
      <form className='admin-auth-form'>
        <h1>Login</h1>
        <input name="email" type="text" placeholder="Enter Email" onChange={(text) => setEmail(text.target.value)} />
        <input name="password" type="password" placeholder="Enter Password" onChange={(text) => setPassword(text.target.value)}/>
          <div className='login-btn'>
            <a onClick={handleLogin}>
              Login
            </a>
          </div>
      </form>
    </div>
  )
}
