import { combineReducers } from "redux";
import I18nSlice from "../services/I18nSlice";

import languageReducer from "./features/languageReducer";
import payoutReducer from "./features/payoutReducer";
import userReducer from "./features/userReducer";

const rootReducer = combineReducers({
   i18n: I18nSlice,
   user: userReducer,
   language: languageReducer,
   payout: payoutReducer,

})

export default rootReducer;