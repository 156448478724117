import React, { useEffect, useState } from 'react'
import testimonies from '../../constants'
import '../../styles/Testimonies.css'
import { SmartText } from './SmartText'
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";

function Testimonies() {
   const t = useSelector(selectTranslations);
   const [isOpened, setIsOpened] = useState(false);
   const [counter, setCounter] = useState(0);
   const handleClick = (type) => {
      if (type === 'more') {
         setCounter(prev => prev + 1);
      } else {
         setCounter(prev => prev - 1);
      }
   }

   useEffect(() => {
      if (counter === 0) {
         setIsOpened(false);
      } else {
         setIsOpened(true)
      }
   }, [counter])
   return (
      <div className='testimonies-wrapper'>
         <h1>{t.testimonials_title}</h1>
         <div className="testimonies" style={{flexDirection: isOpened ? 'column' : 'row'}}>
            <div className="testimony" id="1" style={{width: isOpened ? '100%' : '30%'}}>
               <p>
               <SmartText text={t.testimony_1} handleClick={(val) => handleClick(val)} />.
               </p>
               <h5>{t.author_1}</h5>
            </div>
            <div className="testimony" id="1" style={{width: isOpened ? '100%' : '30%'}}>
               <p>
               <SmartText text={t.testimony_2} handleClick={(val) => handleClick(val)}  />.
               </p>
               <h5>{t.author_2}</h5>
            </div>
            <div className="testimony" id="1" style={{width: isOpened ? '100%' : '30%'}}>
               <p>
               <SmartText text={t.testimony_3} handleClick={(val) => handleClick(val)}  />.
               </p>
               <h5>{t.author_3}</h5>
            </div>
            <div className="testimony" id="1" style={{width: isOpened ? '100%' : '30%'}}>
               <p>
               <SmartText text={t.testimony_4} handleClick={(val) => handleClick(val)} />.
               </p>
               <h5>{t.author_4}</h5>
            </div>
            <div className="testimony" id="1" style={{width: isOpened ? '100%' : '30%'}}>
               <p>
               <SmartText text={t.testimony_5} handleClick={(val) => handleClick(val)} />.
               </p>
               <h5>{t.author_5}</h5>
            </div>
         </div>
      </div>
   )
}

export default Testimonies
