import React from 'react'
import "../../styles/Services.css"
import orientation from "../../images/Expertises/orientation.jpg"
import vie from "../../images/Expertises/vie.jpg"
import CV from "../../images/Expertises/CV.jpg"
import parentale from "../../images/Expertises/parentale.jpg"
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";

function Services() {
   const t = useSelector(selectTranslations);

   return (
      <div className='services-wrapper'>
         <h1>{t.expertise_title}</h1>
         <div className="services">
            <div className='service'>
               <a href='/services'>
                  <img className='service-img' src={orientation} alt="" />
                  <h1>{t.career}</h1>
               </a>
            </div>
            <div className='service'>
               <a href='/services'>
                  <img className='service-img' src={vie} alt="" />
                  <h1>{t.life}</h1>
               </a>
            </div>
            <div className='service'>
               <a href='/services'>
                  <img className='service-img' src={CV} alt="" />
                  <h1>{t.CV}</h1>
               </a>
            </div>
            <div className='service'>
               <a href='/services'>
                  <img className='service-img' src={parentale} alt="" />
                  <h1>{t.parental}</h1>
               </a>
            </div>
         </div>
         <div className="service-button">
            <a href="/services">{t.reserve_btn}</a>
         </div>
      </div>
   )
}

export default Services
