import React from 'react'
import Clients from '../Components/Home/Clients'
import HomeDescription from '../Components/Home/HomeDescription'
import Nav from '../Components/Nav'
import Services from '../Components/Home/Services'
import Testimonies from '../Components/Home/Testimonies'
import Thumbnail from '../Components/Home/Thumbnail'
import '../App.css'
import Footer from '../Components/Footer'

function Home() {
  return (
    <div className='App'>
       <Nav />
       <Thumbnail />
       <HomeDescription />
       <Services />
       <Clients />
       <Testimonies />
       {/*<Subscribe />*/}
       <Footer />
    </div>
  )
}

export default Home
