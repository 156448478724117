import { useEffect, useState } from 'react';
import '../styles/Modal.css';

const Modal = ({ handleClose, show, children, center, small }) => {
  const [isShown, setIsShow] = useState(false);

  return (
    <div 
      className={isShown || show ? "modal display-block" : "modal display-none" }
      onClick={() => {
        setIsShow(false);
        show = false;
      }}
     
      >
      <section className="modal-main" style={{textAlign: center ? 'center' : 'start', width: small ? "50%" : "80%"}}>
        {children}
      </section>
    </div>
  );
};

export default Modal;