import React from 'react'
import Nav from '../Components/Nav'
import ContactHeader from '../Components/Contact/ContactHeader'
import Footer from '../Components/Footer'

function ContactScreen() {
  return (
    <div>
       <Nav />
       <ContactHeader />
       <Footer />
    </div>
  )
}

export default ContactScreen