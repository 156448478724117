import {
   ApolloClient,
   InMemoryCache,
 } from "@apollo/client";

 export const client = new ApolloClient({
   uri: 'https://l3w3raefp5fyxcpt2ppzurvxxe.appsync-api.us-east-2.amazonaws.com/graphql',
   cache: new InMemoryCache(),
   headers: {
       "x-api-key": "da2-tfxl4oqylrgqdawbqwogf5gs6y",
   },
 });

