const initialState = null;

export default function payoutReducer(state = initialState, action) {
  switch (action.type) {
    case "payout/add":
      return {
         ...state,
         state: action.payload
      }
    default:
      return state;
  }
}
