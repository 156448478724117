import {gql} from '@apollo/client';

export const ADD_APPOINTMENT = gql`
   mutation createAppointment($createappointmentinput: CreateAppointmentInput!) {
      createAppointment(input: $createappointmentinput) {
         id
         title
         name
         email
         phone
         startDate
         endDate
         confirmed
   }
 }
`

export const GET_APPOINTMENTS = gql`
query listAppointments {
  listAppointments(filter:{
    startDate:{
      between: ["2022-03-07T12:00:00+02:00", "2022-03-12T11:30:00+02:00"]
    }
  }) {
    items {
      id
      name
      email
      phone
      title
      startDate
      endDate
      confirmed
    }
  }
}
`

export const buildGetAppointments = (start, end) => {
    if(start && end){
        return gql`
        query listAppointments {
  listAppointments(filter:{
    startDate:{
      between: ["${start}", "${end}"]
    }
  }) {
    items {
      id
      title
      name
      email
      phone
      startDate
      endDate
      confirmed
    }
  }
}
        `
    } 
    return GET_APPOINTMENTS
}
