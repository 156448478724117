import React, {useEffect, useState} from 'react'
import { useSelector } from "react-redux"

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import '../styles/AppointmentScreenAdmin.css'
import Footer from '../Components/Footer';
import Nav from '../Components/Nav';
import TransactionTable from '../Components/Admin/TransactionTable';
import { useMutation, useQuery } from '@apollo/client';
import {ADD_APPOINTMENT, buildGetAppointments} from '../Apollo/Queries/queries';
import { Login } from '../firebase/Login';
import Modal from '../Components/Modal';

function AdminScreen() {
   const [selectedDate, setSelectedDate] = useState("");
   const [events, setEvents] = useState([]);
   const [event, setEvent] = useState("");
   const [dateSelected, setDateSelected] = useState(false);
   const [datesSelected, setDatesSelected] = useState({start: "", end: ""});
   const [currentView, setCurrentView] = useState({start: "", end:""});
   const [multipleSelected, setMultipleSelected] = useState(false);
   const user = useSelector((state) => state?.user);
   const token = user?.accessToken;
   const [addAppointment] = useMutation(ADD_APPOINTMENT)
   const getAppQuery = buildGetAppointments(currentView?.start, currentView?.end);
   const { data } = useQuery(getAppQuery)
   const [showModal, setShowModal] = useState(false);
   const authed = window.sessionStorage.getItem("authed");

   const hours = [
      {
         daysOfWeek: [ 1, 4, 5, 6 ],
         startTime: '11:00',
         endTime: '14:00',
       },
       {
         daysOfWeek: [ 2 ],
         startTime: '15:00',
         endTime: '18:00'
       },
       {
         daysOfWeek: [ 1, 4, 5 ],
         startTime: '16:00',
         endTime: '19:00',
       },
   ]


   const handleDateClick = (info) => {
      setSelectedDate(info.dateStr)
      setDateSelected(true);
   }

   const handleClick = () => {
      setShowModal(prev => true)
      let newEvent;
      if (multipleSelected) {
         newEvent = {
            title: event,
            start: datesSelected.start,
            end: datesSelected.end
         }
         setEvents(prev => prev.concat(newEvent));
      } else {
         newEvent = {
            title: event,
            date: selectedDate,
         }
         setEvents(prev => prev.concat(newEvent));
      }

      let tmp = {
         title: newEvent.title,
         startDate: newEvent.start,
         endDate: newEvent.end,
      }


      setSelectedDate("");
      setDatesSelected({start: "", end: ""});

      setMultipleSelected(false);
      setDateSelected(false)
      addAppointment({variables: { createappointmentinput: tmp}}).then(data => console.log('data',data)).catch(err => console.log(err))

   }

   const handleSelectedDates = (date) => {
      setShowModal(prev => true)

      setDateSelected(true);
      setDatesSelected( prev => ({...prev, start: date.startStr}))
      setDatesSelected( prev => ({...prev, end: date.endStr}))
      setMultipleSelected(prev => !prev);
   }


   useEffect(() => {
      let tmpArr = [];
      data?.listAppointments?.items.map(appointment => {
         let tmp = {
            title: appointment.title,
            start: appointment.startDate,
            end: appointment.endDate,
            id: appointment.id
         }
         tmpArr.push(tmp);
      })
      setEvents(tmpArr);
   }, [data])

   if (!authed && !user && !token) {
      return <Login />;
   }

   const handleModal = () => {
      setShowModal(prev => false);
   }


   return (
      <div className="admin">
         <div className='appointment-screen'>
            <Nav />
            <FullCalendar
               schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
               plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  resourceTimeGridPlugin]}
               initialView= 'timeGridWeek'
               headerToolbar={{
                  left: "title",
                  right: "prev,next",
                  center: "timeGridDay,timeGridWeek"
               }}
               height={"100vh"}
               dateClick={handleDateClick}
               selectable={true}
               events={events}
               displayEventTime={true}
               eventBackgroundColor={"#F4B208"}
               eventBorderColor={"#F4B208"}
               dayMaxEventRows={true}
               select={handleSelectedDates}
               
               datesSet={(e) => {
                  setCurrentView({
                     start: e?.startStr,
                     end: e?.endStr
                  })
               }}
               slotMinTime="09:00"
               slotMaxTime="20:00"
               businessHours={hours}
               nextDayThreshold= '00:00:00'
               selectConstraint={hours}
               />
            {dateSelected &&
               <Modal show={showModal} center>
               <div className='appointment-form-wrapper' placeholder='Title'>
                  <input text="text" placeholder='Title' onChange={(e) => setEvent(e.target.value)}/>
                  <button className='add-btn' onClick={handleClick}>Add</button>
                  <button className='add-btn' onClick={handleModal}>Close</button>
               </div>
            </Modal>
            }
            <TransactionTable />
            <Footer />


         </div>
      </div>

  )
}

export default AdminScreen
