import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_APPOINTMENT } from "../Apollo/Queries/queries";
import { useDispatch, useSelector } from "react-redux";
import Nav from "../Components/Nav";
import FooterSpecial from "../Components/FooterSpecial";
import "../styles/SuccessScreen.css";
import { useNavigate } from "react-router";
import { sendEmailApi } from "../Helpers/APIHelpers";

function SuccessScreen() {
  const queryString = window.location.search;
  const paramString = queryString.split("?")[1];
  const urlParams = new URLSearchParams(paramString);
  const Status = urlParams?.get("Status");
  const TransId = urlParams?.get("TransID");

  const language = useSelector((state) => state?.language);
  const payoutInfo = useSelector((state) => state?.payout);
  const [addAppointment] = useMutation(ADD_APPOINTMENT);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { title, amount, email, name, startDate, phone, endDate } =
    payoutInfo?.state;
  useEffect(() => {
    (async () => {
      if (title && amount && email && name) {
        let temp = {
          name: name,
          title: title,
          email: email,
          phone: phone,
          startDate: startDate,
          endDate: endDate,
          confirmed: false,
        };
        try {
          await addAppointment({
            variables: { createappointmentinput: temp },
          });
        } catch (err) {
          console.log("Error in addAppointment: ", err);
        }
        try {
          await sendEmailApi({
            type: "session",
            lang: language === "en" ? "EN" : "FR",
            name,
            email,
            title,
            amount,
            startDate,
          });
        } catch (err) {
          console.log("Error in First Email API Request: ", err);
        }
        try {
          await sendEmailApi({
            type: "payment",
            lang: language === "en" ? "EN" : "FR",
            name,
            email,
            title,
            amount,
            startDate,
          });
        } catch (err) {
          console.log("Error in Second Email API Request: ", err);
        }
      }
    })();
  }, []);

  const handleClick = () => {
    dispatch({ type: "payout/add", payload: {} });
    navigate("/services");
  };

  return (
    <div>
      <Nav />
      <h1 style={{ fontSize: "2.5rem" }}>
        Thank you for confirming your purchase
      </h1>

      <h1>TransID: {TransId}</h1>
      <h2 className="return-button" onClick={handleClick}>
        Return
      </h2>
      <FooterSpecial />
    </div>
  );
}

export default SuccessScreen;
