import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTranslations } from "../../services/I18nSlice";

export default function SingleService({
  title,
  infoClicked,
  price,
  priceText,
  service,
  contact,
}) {
  const t = useSelector(selectTranslations);
  let navigate = useNavigate();

  const handleServiceClick = () => {
    if (contact) {
      navigate("/contact");
    } else {
      navigate("/appointment", {
        state: {
          price: priceText,
          currency: "EUR",
          serviceTitle: title,
          service: service,
        },
      });
    }
  };

  return (
    <div className="services-list-service" style={{ position: "relative" }}>
      <h1>
        {title?.split("\n").map((str) => (
          <p>{str}</p>
        ))}
      </h1>
      <p className="time">{price}</p>
      <div onClick={handleServiceClick}>
        <p>{t.book}</p>
      </div>
      {!!infoClicked && (
        <div onClick={infoClicked} style={styles.infoButton}>
          <AiOutlineInfoCircle
            size={18}
            color="white"
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
}

const styles = {
  infoButton: {
    position: "absolute",
    top: 5,
    right: -10,
    backgroundColor: "transparent",
    borderWidth: 0,
    width: "fit-content",
    height: "fit-content",
  },
};
