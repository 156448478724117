import React from 'react'
import '../styles/Footer.css'
import { useSelector } from "react-redux";
import { selectTranslations } from "../services/I18nSlice";

function Footer() {

  const t = useSelector(selectTranslations);
  return (
    <div className='footer-wrapper'>
      <div className='links-wrapper'>
       <ul>
          <li><a href="/">{t.home_nav}</a></li>
          <li><a href="/services">{t.services_nav}</a></li>
          <li><a href="/">{t.story_nav}</a></li>
          <li><a href="/contact">{t.contact_nav}</a></li>
       </ul>
       <ul>
         <li><a href="">{t.footer_privacy}</a></li>
         <li><a href="">{t.footer_payment}</a></li>
       </ul>
      </div>
        <p>©2022 par <a href="https://hb-tech.org" target={'_blank'}>HBTech.</a></p>
    </div>
  )
}

export default Footer
