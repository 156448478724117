import React from 'react'
import Footer from '../Components/Footer.js'
import Nav from '../Components/Nav.js'
import ServicesHeader from '../Components/Services/ServicesHeader.js'
import ServicesList from '../Components/Services/ServicesList.js'

function ServicesWrapper() {
  return (
    <div>
       <Nav />
       <ServicesHeader />
       <ServicesList />
       <Footer />
    </div>
  )
}

export default ServicesWrapper