import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/I18nSlice";

export const SmartText = ({ text, length = 70, handleClick }) => {
   const t = useSelector(selectTranslations);
   const [showLess, setShowLess] = useState(true);
   if (text?.length < length) {
     return <p>{`${text}`}</p>;
   }
 
   return (
     <div>
       <p
         dangerouslySetInnerHTML={{
           __html: showLess ? `${text?.slice(0, length)}...` : text,
         }}
       ></p>
       <p
         
         style={{ color: "#F4B208 ", cursor: "pointer" }}
         onClick={() => {
           setShowLess(!showLess);
           if (showLess) {
            handleClick("more")

           } else {
            handleClick("less");
           }
           
           }}
       >
         &nbsp;{showLess ? t.view_more : t.view_less}
       </p>
     </div>
   );
 };