const keys = {
  MID: "BNP_PACEM_ECOM",
  Blowfish_key: "iY=2A3q_?xE9b7)Z",
  HMAC_key: "xN_46eC*F[m8d9?K2Hz)q]W37Lf!o5=G",
};

 //test keys
// const keys = {
//    MID: "BNP_DEMO_AXEPTA",
//   Blowfish_key: "Tc5*2D_xs7B[6E?w",
//   HMAC_key: "4n!BmF3_?9oJ2Q*z(iD7q6[RSb5)a]A8",
//  };

export default keys;
