import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { useMutation, useQuery } from "@apollo/client";
import { getHost } from "../Helpers/EncryptionFunctions";
import keys from "../constants.js";

import moment from "moment";

import "../styles/AppointmentScreen.css";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import {
  ADD_APPOINTMENT,
  buildGetAppointments,
} from "../Apollo/Queries/queries";
import { useEffect } from "react";
import Modal from "../Components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../services/I18nSlice";
import { blowfishEncrypt, HMAC256 } from "../Helpers/EncryptionFunctions";

function AppointmentScreen() {
  const [selectedDate, setSelectedDate] = useState("");
  const [timesAvailable, setTimesAvailable] = useState([]);
  const [times, setTimes] = useState("");
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [currentView, setCurrentView] = useState({ start: "", end: "" });
  const [timeSelected, setTimeSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [busyDays, setBusyDays] = useState([]);

  const getAppQuery = buildGetAppointments(
    currentView?.start,
    currentView?.end
  );
  const { data } = useQuery(getAppQuery);
  const [addAppointment] = useMutation(ADD_APPOINTMENT);

  const t = useSelector(selectTranslations);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const location = useLocation();
  const { service, serviceTitle, price, currency } = location?.state;

  const handleDateClick = (info) => {
    if (
      moment(info.dateStr).format("DD") >= moment().format("DD") ||
      moment(info.dateStr).format("MM") > moment().format("MM")
    ) {
      const date = moment(info.dateStr).format("dd");
      setSelectedDate(info.dateStr);
      if (!busyDays.includes(info.dateStr)) {
        switch (service) {
          case "threeHour":
            if (date === "Mo" || date === "We") {
              setTimesAvailable(() => ["16:00-19:00"]);
            } else {
              setTimesAvailable(() => []);
            }
            break;
          case "oneHour":
            if (date === "Tu") {
              setTimesAvailable(() => ["10:00-11:00"]);
            } else if (date === "Th") {
              setTimesAvailable(() => ["18:00-19:00"]);
            } else {
              setTimesAvailable(() => []);
            }
            break;
          default:
            setTimesAvailable(() => []);
            break;
        }
      } else {
        setTimesAvailable(() => []);
      }
    } else {
      setTimesAvailable(() => []);
    }
    setShowModal(true);
  };

  const handleClick = () => {
    let newEvent;

    newEvent = {
      title: serviceTitle,
      date: selectedDate,
    };

    setEvents((prev) => prev.concat(newEvent));
    let tmp = {
      title: newEvent.title,
      name: event.name,
      email: event.email,
      phone: event.phone,
      startDate: `${selectedDate}T${times.substring(0, 5)}:00+02:00`,
      endDate: `${selectedDate}T${times.substring(6, 11)}:00+02:00`,
    };

    setSelectedDate("");
    if (price !== "onDemand") {
      tmp["amount"] = price;
      const host = getHost();
      const transID = Math.floor(Math.random() * 1000000 + 1);
      const HMACValue = HMAC256(price * 100, currency, transID);
      const firstPart = `MerchantID=${keys?.MID}&MsgVer=2.0&TransID=${transID}`;
      // price in the url is in cents, so we need to add "*100" when passing the price to the url
      const pricingPart = `&Amount=${price * 100}&Currency=${currency}`;
      const urlsPart = `&URLBack=${host}/services&URLSuccess=${host}/success&URLNotify=${host}/services&URLFailure=${host}/failed`;
      const unencryptedURL = `${firstPart}${pricingPart}${urlsPart}&MAC=${HMACValue}`;
      const DATA = blowfishEncrypt(unencryptedURL);
      dispatch({ type: "payout/add", payload: tmp });
      navigate("/payment", {
        state: {
          appointmentInfo: tmp,
          DATA: DATA,
          Len: unencryptedURL.length,
        },
      });
    } else {
      addAppointment({ variables: { createappointmentinput: tmp } }).then(
        (data) => console.log("data", data)
      );
    }
  };

  const handleSelectedDates = (date) => {
    let today = new Date();
    let currentDate = moment(today).format("YYYY-MM-DD");
    let chosenDate = moment(date.start).format("YYYY-MM-DD");
    if (
      moment(chosenDate).isAfter(moment(currentDate)) ||
      currentDate === chosenDate
    ) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    let tmpArr = [];
    let busyTemp = [];
    data?.listAppointments?.items.map((appointment) => {
      let tmp = {
        title: appointment.title,
        start: appointment.startDate,
        end: appointment.endDate,
        id: appointment.id,
      };
      busyTemp.push(moment(appointment.startDate).format("YYYY-MM-DD"));
      tmpArr.push(tmp);
    });
    setEvents(tmpArr);
    setBusyDays(busyTemp);
  }, [data]);

  const handleModal = () => {
    setShowModal(false);
    setTimeSelected(false);
  };

  const handleChange = (accessor, val) => {
    let temp = { ...event };
    temp[accessor] = val;
    setEvent(temp);
  };

  const handleTimeClick = (time) => {
    setTimes(time);
    setTimeSelected(true);
  };

  const disabled =
    event.email === "" || event.name === "" || event.phone === "";

  return (
    <div className="appointment-screen">
      <Nav />
      <div style={{ maxHeight: "fit-content" }}>
        <FullCalendar
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin,
          ]}
          initialView="dayGridMonth"
          datesSet={(e) => {
            setCurrentView({
              start: e?.startStr,
              end: e?.endStr,
            });
          }}
          headerToolbar={{
            left: "title",
            right: "prev,next",
          }}
          height={"90vh"}
          dateClick={handleDateClick}
          selectable={true}
          events={events}
          displayEventTime={true}
          dayMaxEventRows={true}
          select={handleSelectedDates}
          showNonCurrentDates={false}
          nextDayThreshold="00:00:00"
          firstDay={1}
        />
      </div>

      {selectedDate && (
        <Modal show={showModal} small={true} center={true}>
          <h1 className="time-title">
            {timesAvailable.length > 0 ? "Select Time" : "No Times Available"}
          </h1>
          <ul className="time-wrapper">
            {timesAvailable?.map((time, i) => {
              return (
                <li key={i?.toString()} onClick={() => handleTimeClick(time)}>
                  {time}
                </li>
              );
            })}
          </ul>
          <button
            className="add-btn"
            onClick={handleModal}
            style={{ cursor: "pointer" }}
          >
            {t.close}
          </button>
        </Modal>
      )}
      {timeSelected && (
        <Modal show={showModal} center>
          <div className="appointment-form-wrapper">
            <input
              type="text"
              placeholder="name"
              onChange={(val) => handleChange("name", val.target.value)}
            />
            <input
              type="text"
              placeholder="email"
              onChange={(val) => handleChange("email", val.target.value)}
            />
            <input
              type="text"
              placeholder="phone"
              onChange={(val) => handleChange("phone", val.target.value)}
            />
            <button
              className="add-btn"
              onClick={handleClick}
              disabled={disabled}
              style={{
                background: disabled && "grey",
                borderColor: disabled && "grey",
                cursor: "pointer",
              }}
            >
              {t.add}
            </button>
            <button
              className="add-btn"
              onClick={handleModal}
              style={{ cursor: "pointer" }}
            >
              {t.close}
            </button>
          </div>
        </Modal>
      )}

      <Footer />
    </div>
  );
}

export default AppointmentScreen;
