import keys from "../constants.js";
const CryptoJS = require("crypto-js");
const blowfish = require("javascript-blowfish");

export const HMAC256 = (amount, currency, transID) => {
  const res = CryptoJS.HmacSHA256(
    `*${transID}*${keys.MID}*${amount}*${currency}`,
    keys.HMAC_key
  );
  const val = CryptoJS.enc.Base64.stringify(res);
  const enc = CryptoJS.enc.Base64.parse(val);
  const plain = CryptoJS.enc.Utf8.parse(enc);
  return CryptoJS.enc.Utf8.stringify(plain);
};

export const getHost = () => {
  if (window && window?.location) {
    const url = window?.location?.href;
    if (url && url?.includes("localhost")) {
      return "http://localhost:3000";
    } else if (url) {
      const parts = url?.split("/");
      return parts[0] + "//" + parts[1] + parts[2];
    }
  }
  return "";
};

export const blowfishEncrypt = (url) => {
  const bf = new blowfish.Blowfish(keys.Blowfish_key);
  const encrypted = bf.encrypt(url);
  const base64 = bf.base64Encode(encrypted);
  return base64ToHex(base64);
};

function base64ToHex(str) {
  const raw = atob(str);
  let result = "";
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : "0" + hex;
  }
  return result.toUpperCase();
}

function hexToBase64(hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      .map(function (a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join("")
  );
}

export const blowfishDecrypt = (data) => {
  const res = hexToBase64(data);
  const bf = new blowfish.Blowfish(keys.Blowfish_key);
  const decrypted64 = bf.base64Decode(res);
  return bf.decrypt(decrypted64);
};
